import dynamic from 'next/dynamic';
import Box  from '@mui/material/Box';
import useDialog from '@components/_hooks/useDialog';
import useWindowSize from '@components/_hooks/useWindowSize';
import { useSelector } from 'react-redux';

const HeaderChoosePricingModel = dynamic(() => import("././HeaderChoosePricingModel"), {
  ssr: true
});
const HeaderMobileBar1 = dynamic(() => import("./HeaderMobileBar1"), {
  ssr: true
});
const HeaderMobileBar2 = dynamic(() => import("./HeaderMobileBar2"), {
  ssr: true
});
const Header = dynamic(() => import("../Header"), {
  ssr: true
});
const HeaderCategories = dynamic(() => import("./HeaderCategories"), {
  ssr: true
});

const HeaderMobile = ({route}) => {

  const {width, isMobile} = useWindowSize();
  const {dialog, handleDialog} = useDialog();
  const pricing_model = useSelector((state) => state.globalReducer.pricing_model);

  return (
    <>
    {( isMobile || width < 1000)? (
      <Box>
        <HeaderChoosePricingModel pricing_model={pricing_model} />
        <HeaderMobileBar1 dialog={dialog} handleDialog={handleDialog}/>
        <HeaderMobileBar2/>
        {
          dialog['top']?.open ? (
            <>
              <HeaderCategories route={route} handleDialog={() => handleDialog('top')} type="mobile" />
            </>
          ) : null
        }
      </Box>
      ):(
      <Header/>
      )
    }
    </>
  )
}

export default HeaderMobile;